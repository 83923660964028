.Header {
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 4rem 0 2rem 0;
    width: 100%;
    align-items: left;
}

.Header--Logo {
    max-height: 12rem;
}

.Header--Text {
    font-family: 'PlayfairDisplay', sans-serif;
    font-size: 4rem;
    margin-left: 1rem;
    text-align: center;
}

.bold-i-guess {
    font-family: 'PlayfairDisplay', sans-serif;
    font-size: 2rem;
    margin-left: 1rem;
    text-align: left;
    font-weight: 700;
}

.h4--Text {
    font-family: 'PlayfairDisplay', sans-serif;
    font-size: 2rem;
    margin-left: 1rem;
    text-align: left;
    font-weight: 400;
}